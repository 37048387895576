import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import gsap from "gsap";
import { InteractionManager } from "three.interactive";
import { Scene } from "three";

const scene = new THREE.Scene();
let container = $(".renderer");
const sizes = {
    width: container.innerWidth(),
    height: container.innerHeight(),
};
window.addEventListener("resize", () => {
    // update sizes
    sizes.width = container.innerWidth();
    sizes.height = container.innerHeight();

    //update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    //   update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});
const camera = new THREE.PerspectiveCamera(
    16,
    sizes.width / sizes.height,
    1,
    1000
);
const renderer = new THREE.WebGLRenderer({ alpha: true });
renderer.gammaFactor = 2.2;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
container.append(renderer.domElement);
// event listener manager
const interactionManager = new InteractionManager(
    renderer,
    camera,
    renderer.domElement
);

// earth model
const loader = new GLTFLoader();
// Load a glTF resource
// orange material
let selected = new THREE.MeshStandardMaterial({ color: 0xc44800 });
let empySphere = null;
let empySphere2 = null;
let greenColor = null;
let sphere = null;
let africa = null;
let europe = null;
let australia = null;
let america = null;
// selected
let africaActive = true;
let europeActive = false;
let australiaActive = false;
let americaActive = false;
// rotation
let rotation = true;
loader.load(
    // resource URL
    "assets/3d-files/sphereCont.glb",
    // called when the resource is loaded
    function (gltf) {
        sphere = gltf.scene;
        gltf.animations; // Array<THREE.AnimationClip>
        gltf.scene; // THREE.Group
        gltf.scenes; // Array<THREE.Group>
        gltf.cameras; // Array<THREE.Camera>
        gltf.asset; // Object
        gltf.scene.scale.set(3, 3, 3);
        // gltf.scene.scale.set(2, 2, 2);

        sphere.traverse(function (child) {
            if (child.isMesh) {
                if (child.name === "africa") {
                    // child.material = selected;
                    africa = child;
                    greenColor = africa.material;
                    interactionManager.add(africa);
                    africa.addEventListener("click", (event) => {
                        $([document.documentElement, document.body]).animate(
                            {
                                scrollTop: $("#operations").offset().top,
                            },
                            0
                        );
                        $(".continent").text("Africa");
                        africa.material = selected;
                        europe.material = greenColor;
                        america.material = greenColor;
                        australia.material = greenColor;
                        africaActive = true;
                        europeActive = false;
                        australiaActive = false;
                        americaActive = false;
                        //
                        AfSelected = true;
                        NaSelected = false;
                        EuSelected = false;
                        AuSelected = false;
                        classClean();
                        $(".list-box")
                            .children()
                            .children()
                            .eq(0)
                            .children()
                            .addClass("selected");
                    });
                } else if (child.name === "europe") {
                    europe = child;
                    interactionManager.add(europe);
                    europe.addEventListener("click", (event) => {
                        $([document.documentElement, document.body]).animate(
                            {
                                scrollTop: $("#operations").offset().top,
                            },
                            0
                        );
                        $(".continent").text("Europe");

                        // gsap.to(sphere.rotation, {
                        //     duration: 1.2,
                        //     delay: 0,
                        //     x: 0,
                        //     y: ,
                        // });
                        africa.material = greenColor;
                        europe.material = selected;
                        america.material = greenColor;
                        australia.material = greenColor;
                        africaActive = false;
                        europeActive = true;
                        australiaActive = false;
                        americaActive = false;
                        //
                        AfSelected = false;
                        NaSelected = false;
                        EuSelected = true;
                        AuSelected = false;
                        classClean();
                        $(".list-box")
                            .children()
                            .children()
                            .eq(2)
                            .children()
                            .addClass("selected");
                    });
                } else if (child.name === "north-america") {
                    america = child;
                    interactionManager.add(america);
                    america.addEventListener("click", (event) => {
                        $([document.documentElement, document.body]).animate(
                            {
                                scrollTop: $("#operations").offset().top,
                            },
                            0
                        );
                        $(".continent").text("North America");

                        africa.material = greenColor;
                        europe.material = greenColor;
                        america.material = selected;
                        australia.material = greenColor;
                        africaActive = false;
                        europeActive = false;
                        australiaActive = false;
                        americaActive = true;
                        //
                        AfSelected = false;
                        NaSelected = true;
                        EuSelected = false;
                        AuSelected = false;
                        classClean();
                        $(".list-box")
                            .children()
                            .children()
                            .eq(1)
                            .children()
                            .addClass("selected");
                    });
                } else if (child.name === "australia") {
                    australia = child;
                    interactionManager.add(australia);
                    // console.log(intersects[0].distance);
                    australia.addEventListener("click", (event) => {
                        $([document.documentElement, document.body]).animate(
                            {
                                scrollTop: $("#operations").offset().top,
                            },
                            0
                        );
                        $(".continent").text("Australia");

                        africa.material = greenColor;
                        europe.material = greenColor;
                        america.material = greenColor;
                        australia.material = selected;
                        africaActive = false;
                        europeActive = false;
                        australiaActive = true;
                        americaActive = false;
                        //
                        AfSelected = false;
                        NaSelected = false;
                        EuSelected = false;
                        AuSelected = true;
                        classClean();
                        $(".list-box")
                            .children()
                            .children()
                            .eq(3)
                            .children()
                            .addClass("selected");
                    });
                } else if (child.name === "Sphere004") {
                    empySphere = child;
                    // interactionManager.add(empySphere);
                    // empySphere.addEventListener("click", (event) => {
                    //     console.log("clicked on globe");
                    //     raycasterSelect = true;
                    // });
                } else if (child.name === "Sphere004_1") {
                    empySphere2 = child;
                }
            }
        });
        scene.add(gltf.scene);
    },
    // called while loading is progressing
    function (xhr) {
        // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    // called when loading has errors
    function (error) {
        // console.log("An error happened");
    }
);

const raycaster = new THREE.Raycaster();
// cursor
const mouse = new THREE.Vector2();
let containerJs = document.querySelector(".renderer");
containerJs.addEventListener("mousemove", function (e) {
    mouse.x = (e.offsetX / containerJs.clientWidth) * 2 - 1;
    mouse.y = -(e.offsetY / containerJs.clientHeight) * 2 + 1;
});
const light = new THREE.AmbientLight(0x404040, 0.8); // soft white light
scene.add(light);
const directionalLight = new THREE.DirectionalLight(0xffffff, 1.2);
directionalLight.position.set(1, 5, 10);
scene.add(directionalLight);
camera.position.z = 22;
const controls = new OrbitControls(camera, renderer.domElement);
controls.enableDamping = true;
controls.enableZoom = false;
controls.enablePan = false;
let currentIntersect = null;

const raycasterPoints = new THREE.Raycaster();

const points = [
    // africa
    {
        position: new THREE.Vector3(3, 1, -0.6),
        element: document.querySelector(".point-0"),
    },
    {
        position: new THREE.Vector3(2.2, 1.1, -2.2),
        element: document.querySelector(".point-1"),
    },
    {
        position: new THREE.Vector3(2.2, -1.1, -2.0),
        element: document.querySelector(".point-2"),
    },
    // europe
    {
        position: new THREE.Vector3(1.2, 2.7, -0.9),
        element: document.querySelector(".point-3"),
    },
    {
        position: new THREE.Vector3(0.6, 2.5, -1.6),
        element: document.querySelector(".point-4"),
    },
    {
        position: new THREE.Vector3(1.2, 2.4, -1.9),
        element: document.querySelector(".point-5"),
    },
    // australia
    {
        position: new THREE.Vector3(-1.1, -2.6, -1.1),
        element: document.querySelector(".point-6"),
    },
    // usa
    {
        position: new THREE.Vector3(-1, 0, 2.9),
        element: document.querySelector(".point-7"),
    },
    {
        position: new THREE.Vector3(-0.2, 0, 3.1),
        element: document.querySelector(".point-8"),
    },
    {
        position: new THREE.Vector3(0.1, 1, 2.9),
        element: document.querySelector(".point-9"),
    },
];
const animate = function () {
    controls.update();
    controls.autoRotate = true;
    raycaster.setFromCamera(mouse, camera);
    if (sphere) {
        interactionManager.update();
        const objectsToTest = [africa, europe, america, australia];
        const intersects = raycaster.intersectObjects(objectsToTest);
        for (const object of objectsToTest) {
            $("html,body").css("cursor", "default");

            // object.material = selected;
        }
        for (const intersect of intersects) {
            const intersectionDistance = intersects[0].distance;
            if (intersectionDistance > 21) {
                intersect.object.material = greenColor;
            }
        }
        if (intersects.length) {
            if (currentIntersect === null) {
                // console.log("mouse enter");
                // intersects[0].object.material = selected;
                if (intersects[0].distance < 21) {
                    intersects[0].object.material = selected;
                }
            }
            currentIntersect = intersects[0];
        } else {
            if (currentIntersect) {
                // console.log("mouseleave");
                currentIntersect.object.material = greenColor;
            }
            currentIntersect = null;
        }
        // points
        for (const point of points) {
            point.element.style.transform -= `translateX(${0.01}px)`;
            let screenPosition = point.position.clone();
            screenPosition.project(camera);
            raycasterPoints.setFromCamera(screenPosition, camera);
            const intersects = raycasterPoints.intersectObjects(
                scene.children,
                true
            );
            if (intersects.length === 0) {
                point.element.classList.add("visible");
            } else {
                const intersectionDistance = intersects[0].distance;
                const pointDistance = point.position.distanceTo(
                    camera.position
                );
                if (intersectionDistance < pointDistance) {
                    point.element.classList.remove("visible");
                } else {
                    point.element.classList.add("visible");
                }
            }
            const translateX = screenPosition.x * sizes.width * 0.5;
            const translateY = -screenPosition.y * sizes.height * 0.5;
            point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
            // stop rotation
            africa.addEventListener("click", (event) => {});
        }
    }

    directionalLight.position.copy(camera.position);
    renderer.render(scene, camera);
    window.requestAnimationFrame(animate);
};

animate();
// second scene
//
//
//
const sceneSmall = new THREE.Scene();
let containerSmall = $(".small-render");
const sizesSmall = {
    width: containerSmall.innerWidth(),
    height: containerSmall.innerHeight(),
};
window.addEventListener("resize", () => {
    // update sizes
    sizesSmall.width = containerSmall.innerWidth();
    sizesSmall.height = containerSmall.innerHeight();

    //update camera
    cameraSmall.aspect = sizesSmall.width / sizesSmall.height;
    cameraSmall.updateProjectionMatrix();

    //   update renderer
    rendererSmall.setSize(sizesSmall.width, sizesSmall.height);
    rendererSmall.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});
const cameraSmall = new THREE.PerspectiveCamera(
    10,
    sizesSmall.width / sizesSmall.height,
    1,
    1000
);
const lightSmall = new THREE.AmbientLight(0x404040, 0.8); // soft white light
sceneSmall.add(lightSmall);
const directionalLightSmall = new THREE.DirectionalLight(0xffffff, 1.2);
directionalLightSmall.position.set(1, 5, 10);
sceneSmall.add(directionalLightSmall);
const rendererSmall = new THREE.WebGLRenderer({ alpha: true });
rendererSmall.gammaFactor = 2.2;
rendererSmall.outputEncoding = THREE.sRGBEncoding;
rendererSmall.setSize(sizesSmall.width, sizesSmall.height);
rendererSmall.setPixelRatio(Math.min(window.devicePixelRatio, 2));
containerSmall.append(rendererSmall.domElement);

// earth model
const loaderSmall = new GLTFLoader();
// Load a glTF resource
let mixer = null;
let obj = null;
loaderSmall.load(
    // resource URL
    "assets/3d-files/continents-fix.glb",
    // called when the resource is loaded
    function (gltf) {
        gltf.animations; // Array<THREE.AnimationClip>
        mixer = new THREE.AnimationMixer(gltf.scene);
        for (let i = 0; i < gltf.animations.length; i++) {
            let action = mixer.clipAction(gltf.animations[i]);
            action.play();
        }
        obj = gltf.scene; // THREE.Group
        gltf.scenes; // Array<THREE.Group>
        gltf.cameras; // Array<THREE.Camera>
        gltf.asset; // Object
        gltf.scene.scale.set(0.65, 0.65, 0.65);
        gltf.scene.position.set(0, -0.1, 0);
        // gltf.scene.scale.set(1, 1, 1);

        // gltf.scene.traverse(function (child) {
        //     if (child.isMesh) {
        //         if (child.name === "Sphere007") {
        //             child.visible = false;
        //             if (africaActive) {
        //                 child.visible = true;
        //             }
        //         } else if (child.name === "europe_v2") {
        //             child.visible = false;
        //             if (europeActive) {
        //                 child.visible = true;
        //             }
        //         } else if (child.name === "north-america_v2") {
        //             child.visible = false;
        //             if (americaActive) {
        //                 child.visible = true;
        //             }
        //         } else if (child.name === "australia_v2") {
        //             child.visible = false;
        //             if (australiaActive) {
        //                 child.visible = true;
        //             }
        //         }
        //     }
        // });
        sceneSmall.add(gltf.scene);
        // Control
        const SceneJq = $(".small-render");
        const Scene = document.querySelector(".small-render");
        let mouseTolerance = 0.5;
        let screenWidth = Scene.offsetWidth;
        let screenHeight = Scene.offsetHeight;
        SceneJq.on("mouseenter", (e) => {
            Scene.onmousemove = function (e) {
                const Scene = this.getBoundingClientRect();
                let centerX = screenWidth / 2;
                let centerY = screenHeight / 2;
                let y = e.clientY - Scene.top;
                let x = e.clientX - Scene.left;
                let rotateY = ((x - centerX) / centerX) * mouseTolerance;
                let rotateX = ((y - centerY) / centerY) * mouseTolerance;
                gsap.to(obj.rotation, {
                    duration: 0.8,
                    delay: 0,
                    x: rotateX,
                    y: rotateY,
                });
            };
        }).on("mouseleave", () => {
            gsap.to(obj.rotation, { duration: 1.2, delay: 0, x: 0, y: 0 });
        });
    },
    // called while loading is progressing
    function (xhr) {
        // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    // called when loading has errors
    function (error) {
        // console.log("An error happened");
    }
);
cameraSmall.position.z = 5;

const clock = new THREE.Clock();
let previousTime = 0;
const continentSelect = $(".list-box").find("a");
let AfSelected = false;
let NaSelected = false;
let EuSelected = false;
let AuSelected = false;
let noneSelected = true;
const animateSmall = function () {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;
    directionalLightSmall.position.copy(cameraSmall.position);
    // update mixer
    if (mixer !== null) {
        mixer.update(deltaTime);
    }
    if (noneSelected) {
        $(".list-box").find("a").eq(0).addClass("selected");
    }
    continentSelect.on("click", (e) => {
        noneSelected = false;
        classClean();
        e.preventDefault();
        let target = e.target.innerHTML;
        let btn = e.target;
        $(btn).addClass("selected");
        if (target === "Africa") {
            AfSelected = true;
            NaSelected = false;
            EuSelected = false;
            AuSelected = false;
            australiaActive = false;
            africaActive = true;
            americaActive = false;
            europeActive = false;
            $(".continent").text("Africa");
        } else if (target === "North America") {
            AfSelected = false;
            NaSelected = true;
            EuSelected = false;
            AuSelected = false;
            australiaActive = false;
            africaActive = false;
            americaActive = true;
            europeActive = false;
            $(".continent").text("North America");
        } else if (target === "Europe") {
            AfSelected = false;
            NaSelected = false;
            EuSelected = true;
            AuSelected = false;
            australiaActive = false;
            africaActive = false;
            americaActive = false;
            europeActive = true;
            $(".continent").text("Europe");
        } else if (target === "Australia") {
            AfSelected = false;
            NaSelected = false;
            EuSelected = false;
            AuSelected = true;
            australiaActive = true;
            africaActive = false;
            americaActive = false;
            europeActive = false;
            $(".continent").text("Australia");
        }
    });
    if (obj) {
        obj.traverse(function (child) {
            if (child.isMesh) {
                if (child.name === "africa_v2") {
                    child.visible = false;
                    if (africaActive || AfSelected) {
                        child.visible = true;
                        noneSelected = false;
                    } else if (!AfSelected) {
                        child.visible = false;
                    }
                } else if (child.name === "europe_v2") {
                    child.visible = false;
                    if (europeActive || EuSelected) {
                        child.visible = true;
                        noneSelected = false;
                    } else if (!EuSelected) {
                        child.visible = false;
                    }
                } else if (child.name === "north-america_v2") {
                    child.visible = false;
                    if (americaActive || NaSelected) {
                        child.visible = true;
                        noneSelected = false;
                    } else if (!NaSelected) {
                        child.visible = false;
                    }
                } else if (child.name === "australia_v2") {
                    child.visible = false;
                    if (australiaActive || AuSelected) {
                        child.visible = true;
                        noneSelected = false;
                    } else if (!AuSelected) {
                        child.visible = false;
                    }
                }
            }
        });
    }

    rendererSmall.render(sceneSmall, cameraSmall);
    window.requestAnimationFrame(animateSmall);
};
// utility functions
// clean classes
function classClean() {
    $(".list-box").find("a").removeClass("selected");
}

classClean();
animateSmall();
